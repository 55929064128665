











































import {Component, Vue} from "vue-property-decorator";
import MyFooter from "@/components/MyFooter.vue";
import {UserInfoResponse, apiUser} from "@API/ApiUser";

@Component({
  name: "Mine",
  components: {MyFooter},
})
export default class TabFour extends Vue {
  public userInfo: UserInfoResponse | object = {}

  created() {
    this.request()
  }

  request() {
    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })

    apiUser.userInfo()
        .finally(() => {
          loading.clear()
        })
        .then(res => {
          this.userInfo = res.data
        })
        .catch(e => {
          this.$dialog({
            title: '获取个人中心失败',
            message: e.message
          })
        })
  }

  handleLogout() {
    this.$dialog.confirm({
      title: '退出',
      message: '确认要退出登录吗？',
    }).then(() => {
      localStorage.removeItem('is_login')
      sessionStorage.clear()
      apiUser.logout()
      this.$router.push('/user/login')
    })
  }

  handleToEmployee() {
    this.$router.push({
      name: 'employee'
    })
  }
}
